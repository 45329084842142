import React, { Component } from 'react'
import Select from 'react-select'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

import styles from './contact.module.sass'

const options = [
   { value: 'wealth-preservation', label: 'Wealth Preservation' },
   { value: 'college-funding', label: 'College Funding' },
   { value: 'asset-allocation', label: 'Asset Allocation' },
   { value: 'planned-gifting', label: 'Planned Gifting' },
   { value: 'legacy-planning', label: 'Legacy Planning' },
   { value: 'retirement-planning', label: 'Retirement Planning' },
   { value: 'succession-planning', label: 'Succession Planning' },
   { value: 'income-tax-planning', label: 'Income Tax Planning' },
   { value: 'estate-tax-planning', label: 'Estate Tax Planning' },
   { value: 'risk-management', label: 'Risk Management (Insurance)' },
   { value: 'portfolio-management', label: 'Portfolio Management' },
   { value: 'second-opinion', label: 'Second Opinion' },
]

class Contact extends Component {
   state = {
      selectedOption: null,
   }

   handleChange = (selectedOption) => {
      this.setState({ selectedOption }, () =>
         console.log(`Option selected:`, this.state.selectedOption)
      )
   }

   render() {
      const { selectedOption } = this.state
      return (
         <div className={styles.contact_inner}>
            <section>
               <form
                  name="contact"
                  method="post"
                  action="/success?no-cache=1"
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  className={[
                     styles.contactForm,
                     this.props.page.includes('signup | Post: ')
                        ? styles.signupFromPost
                        : '',
                  ].join(' ')}>
                  <input type="hidden" name="form-name" value="contact" />
                  <input type="hidden" name="bot-field" />
                  <input
                     type="hidden"
                     name="page"
                     value={!!this.props.page ? this.props.page : 'Unknown'}
                  />
                  <input
                     type="hidden"
                     name="campaign"
                     value={!!this.props.campaign ? this.props.campaign : 'N/A'}
                  />
                  <input
                     type="hidden"
                     name="source"
                     value={!!this.props.source ? this.props.source : 'N/A'}
                  />
                  <input
                     type="hidden"
                     name="medium"
                     value={!!this.props.medium ? this.props.medium : 'N/A'}
                  />
                  <input
                     type="hidden"
                     name="service-origin"
                     value={
                        typeof window !== `undefined`
                           ? new URLSearchParams(window.location.search).get(
                                'service'
                             )
                              ? new URLSearchParams(window.location.search).get(
                                   'service'
                                )
                              : 'none'
                           : ''
                     }
                  />
                  <input
                     className={
                        this.props.page !== 'new-client' ? styles.hidden : ''
                     }
                     type="hidden"
                     name="interested-services"
                     value={
                        !!selectedOption && selectedOption.length > 0
                           ? JSON.stringify(selectedOption)
                           : 'N/A'
                     }
                  />
                  <div className={[styles.element, styles.name].join(' ')}>
                     <label htmlFor="name">
                        Name
                        <input
                           required
                           minLength="2"
                           maxLength="30"
                           pattern="[A-Za-z ]{1,30}"
                           name="name"
                           id="name"
                           type="text"
                           className={styles.control}
                           placeholder="John Doe"
                        />
                     </label>
                  </div>
                  <div className={[styles.element, styles.email].join(' ')}>
                     <label htmlFor="email">
                        Email
                        <input
                           required
                           pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
                           name="email"
                           id="email"
                           type="email"
                           className={styles.control}
                           placeholder="john.doe@example.com"
                        />
                     </label>
                  </div>
                  <div
                     className={[
                        styles.element,
                        styles.phone,
                        this.props.page === 'home' ||
                        this.props.page.includes('signup')
                           ? styles.hidden
                           : '',
                     ].join(' ')}>
                     <label htmlFor="phone">
                        Phone <small>(OPTIONAL)</small>
                        <input
                           name="phone"
                           id="phone"
                           type="tel"
                           className={styles.control}
                           placeholder="301-770-6800"
                        />
                     </label>
                  </div>
                  <div
                     className={[
                        styles.element,
                        styles.time,
                        this.props.page === 'home' ||
                        this.props.page.includes('signup')
                           ? styles.hidden
                           : '',
                     ].join(' ')}>
                     <label htmlFor="time">
                        Preferred Time <small>(OPTIONAL)</small>
                        <input
                           name="time"
                           id="time"
                           type="text"
                           className={styles.control}
                           placeholder="Morning, afternoon, evening..."
                        />
                     </label>
                  </div>
                  <div
                     className={[
                        styles.element,
                        styles.services,
                        this.props.page !== 'new-client' ? styles.hidden : '',
                     ].join(' ')}>
                     <label htmlFor="services">Interested Services</label>
                     <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                        isMulti
                        className="select select--multi"
                     />
                  </div>
                  <div
                     className={[
                        styles.element,
                        styles.message,
                        this.props.page.includes('signup | Post: ')
                           ? styles.hidden
                           : '',
                     ].join(' ')}>
                     <label htmlFor="message">
                        Message
                        {this.props.page.includes('signup') && (
                           <small> (OPTIONAL)</small>
                        )}
                        <textarea
                           required={!this.props.page.includes('signup')}
                           minLength="10"
                           name="message"
                           id="message"
                           cols="30"
                           rows="10"
                           className={styles.control}
                           placeholder="Questions, comments, etc..."
                        />
                     </label>
                  </div>
                  <div
                     className={[
                        styles.element,
                        styles.newsletter,
                        this.props.page.includes('signup | Post: ')
                           ? styles.hidden
                           : '',
                     ].join(' ')}>
                     <label htmlFor="newsletter">
                        Send your{' '}
                        <a href="/ctn" target="_blank">
                           weekly financial recap
                        </a>{' '}
                        my way!
                        <input
                           name="newsletter"
                           id="newsletter"
                           type="checkbox"
                           defaultChecked
                           className={styles.control}
                        />
                        <span className={styles.checkmark}></span>
                     </label>
                  </div>
                  <div className={styles.actions}>
                     <button
                        type="submit"
                        className="button button--primary button--full button--rounded button--shadow">
                        Start the Conversation <span>&rarr;</span>
                     </button>
                  </div>
                  <div className={styles.disclaimer}>
                     By submitting this form, you agree to SPC Financial, Inc.'s{' '}
                     <a href="/legal/terms" target="_blank">
                        Terms of Use
                     </a>{' '}
                     and{' '}
                     <a href="/legal/privacy" target="_blank">
                        Privacy Policy
                     </a>
                  </div>
               </form>
            </section>
         </div>
      )
   }
}

export default Contact
